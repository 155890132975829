import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home/home.vue'
import about from '@/views/about/about.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    beforeEnter() {
      setTimeout(function(){window.location = "https://preview.untoldstori.es/";},250);
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})


export default router
