<template>
  <div>

    <video playsinline autoplay muted loop 
      class="video collapsed" 
      id="video"
      ref="video"
      preload="metadata" 
      poster="poster.jpg"
      >
      <source src="UNTOLD_STORIES_BACKGROUND_1_540p.mp4#t=10,250" type="video/mp4" />
    </video>
    
    <div class="container">
      <h1>Untold Stories</h1>
      <h2>Cinema</h2>
      <div class="project-description">
        <p>An online video installation that gives light, space, and voice
to unsung heroes, unseen realities, and unheard stories.
It invites audiences to explore films behind their cinematic formats and create new ones from
outtakes and other forgotten material shot by professional filmmakers.
        </p>
        <p>
Subscribe to our monthly newsletter to hear more about
participating filmmakers and upcoming events.
        </p>
    </div>
      
      <mailchimp class="subscribe"
        action="https://studio.us6.list-manage.com/subscribe/post?u=df38a70609e66b815915f469d&id=9349940397"
      validation="b_df38a70609e66b815915f469d_9349940397"
      />

      <div class="partners">
        <div class="partners-bar">
          <div><a href="https://stimuleringsfonds.nl/en/" target="_blank"><img src="/SVG/Artboard 1.svg" /></a></div>
          <div><a href="https://storytellers.link/" target="_blank"><img src="/SVG/Artboard 2.svg" /></a></div>
          <div><a href="https://www.dfi.dk/en" target="_blank"><img src="/SVG/Artboard 3.svg" /></a></div>
          <div><a href="https://rndr.studio/" target="_blank"><img src="/SVG/Artboard 4.svg" /></a></div>
          <div><a href="https://www.kasparai.com/" target="_blank"><img src="/SVG/Artboard 5.svg" /></a></div>
          <div><a href="https://openrndr.org/" target="_blank"><img src="/SVG/Artboard 6.svg" /></a></div>
          <div><a href="https://www.beeldengeluid.nl/en" target="_blank"><img src="/SVG/Artboard 7.svg" /></a></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Mailchimp from "../mailchimp/mailchimp";

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      startTime: 20,
      sampleItem: ''
    }
  },
  created: function () {
    this.sampleItem = 'hello world'
  },
  mounted() {
    // window.open("https://preview.untoldstori.es/");
  },
  components: {
    'mailchimp': Mailchimp
  },
  // computed: {},
  // mounted: function () {

  // },
  // methods: {
  //   myMethod: function () {

  //   }
  // },
  // watch:{
  //   thing (to, from){
  //   }
  // }

});
</script>
<style scoped src="./home.styl" lang="stylus"></style>
